import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Enum from "../../abstract/Enum";
import { useUploadDocument } from "../../hooks/useUploadDocument";
import FileField from "../../fields/FileField";
import { useRef } from "react";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import Button from "@mui/material/Button";
import TextField from "../../fields/TextField";

// https://bobbyhadz.com/blog/react-open-file-input-on-button-click

export default function AdvertImage({ advert }) {
  const { sectionWithId } = useCollectionContext();

  return (
    <div>
      <div className="mt-2">
        <ImagePort advert={advert} size={Enum.LARGE_PORT} />
      </div>
      <div className="mt-2">
        <ImagePort advert={advert} size={Enum.SMALL_PORT} />
      </div>
    </div>
  );
}

// ---------------------------------------------------------

export function ImagePort({ advert, size }) {
  const { sectionWithId } = useCollectionContext();
  const [imgUrl, setImgUrl] = useState("");
  const [portSize, setPortSize] = useState({ width: 0, height: 0 });
  const { uploadImage } = useUploadDocument();
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [data, setData] = useState({ url: "" });

  // run once on mount
  useEffect(() => {
    resolveSize();
    if (advert) {
      if (size == Enum.LARGE_PORT) {
        setImgUrl(advert.largeImg);
      }
      if (size == Enum.SMALL_PORT) {
        setImgUrl(advert.smallImg);
      }
    }
  }, []);

  const handleClick = () => {
    // uploadImage(image, path);
    toast.success("add image");
  };

  // const updateData = async () => {
  //   // project data
  //   let projectData = {};

  //   // avatar
  //   if (data.avatorImage) {
  //     const imageUrl = await uploadImage(data.avatorImage, "avatar");

  //     // updating data
  //     projectData = {
  //       name: data.name,
  //       email: data.email,
  //       avatar: imageUrl,
  //     };
  //   } else {
  //     // updating data without changing the image
  //     projectData = {
  //       name: data.name,
  //       email: data.email,
  //       avatar: data.avatar,
  //     };
  //   }

  //   // udpate the profile
  //   const docRef = doc(db, "members", member.id);

  //   console.log(projectData);

  //   await setDoc(docRef, projectData, { merge: true }).then(() => {
  //     setIsActive(false);
  //     data.avatorImage = ""; // ! I must kill the file-object otherwise it will not work
  //     toast.success("project data updated");
  //   });
  // };

  const resolveSize = () => {
    switch (size) {
      case Enum.LARGE_PORT:
        setPortSize({ width: 800, height: 200 });
        return;
      case Enum.MEDIUM_PORT:
        setPortSize({ width: 500, height: 200 });
        return;
      default:
        setPortSize({ width: 300, height: 200 });
        return;
    }
  };

  const handlePortClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const inputRef = useRef(null);
  const handleFileChange = async (event) => {
    const advertSection = sectionWithId(advert.sectionId);

    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    let imageUrl = await uploadImage(fileObj, "adverts");

    // adjust url for imageKit
    // Replace this:
    // https://firebasestorage.googleapis.com/v0/b/mismaar-f678a.appspot.com
    // With This
    //ik.imagekit.io/habiatuq
    imageUrl = imageUrl.replace(
      "firebasestorage.googleapis.com/v0/b/mismaar-f678a.appspot.com",
      "ik.imagekit.io/habiatuq"
    );

    console.log("imageUrl: ", imageUrl);

    setImgUrl(imageUrl);

    // small image
    if (size == Enum.LARGE_PORT) {
      advert.largeImg = imageUrl;
    }

    // small iamge
    if (size == Enum.SMALL_PORT) {
      advert.smallImg = imageUrl;
    }

    advertSection.fuse(advert);
    const d = advertSection.data();
    const docRef = doc(db, Enum.ADVERTS_SECTION, advert.sectionId);
    await setDoc(docRef, d, { merge: true }).then((res) => {
      toast.success("image added");
    });

    // console.log("fileObj is", fileObj);

    // 👇️ reset file input
    event.target.value = null;

    // 👇️ is now empty
    // console.log(event.target.files);
    // 👇️ can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.name);
  };

  const onChange = (val) => {};

  const handleAddImageLink = async () => {
    const advertSection = sectionWithId(advert.sectionId);

    // large linked image
    if (size == Enum.LARGE_PORT) {
      advert.largeImg = data.url;
    }

    // small linked iamge
    if (size == Enum.SMALL_PORT) {
      advert.smallImg = data.url;
    }

    advertSection.fuse(advert);
    const d = advertSection.data();
    const docRef = doc(db, Enum.ADVERTS_SECTION, advert.sectionId);
    await setDoc(docRef, d, { merge: true }).then((res) => {
      setShowLinkForm(false);
      toast.success("image added");
    });
  };

  return (
    <div>
      <div
        className={`bg-gray-500 relative`}
        style={{
          width: portSize.width,
          minHeight: portSize.height,
        }}
      >
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />

        <img className="absolute top-0 left-0" src={imgUrl} />

        <div
          onClick={handlePortClick}
          className="absolute text-3xl font-bold cursor-pointer z-10"
        >
          upload Image
        </div>
        {/* <div
          onClick={() => setShowLinkForm(!showLinkForm)}
          className="absolute text-3xl font-bold cursor-pointer z-10"
        >
          link Image
        </div> */}
      </div>
      <div className="relative mx-2">{imgUrl}</div>
      {showLinkForm && (
        <div>
          <div className="">
            <TextField
              label="image link"
              isActive={true}
              id="url"
              valKey="url"
              data={data}
              setData={setData}
            />
          </div>
          <Button onClick={handleAddImageLink} variant="contained">
            add
          </Button>
          <Button onClick={() => setShowLinkForm(false)} variant="contained">
            cancel
          </Button>
        </div>
      )}
    </div>
  );
}
