import { useEffect, useState } from "react";
import TextField from "../../fields/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { useLogin } from "../../hooks/useLogin";
import { useAppContext } from "../../hooks/useAppContext";
import Enum from "../../abstract/Enum";
import { toast } from "react-toastify";
import LANG from "../../abstract/LANG";

import "../../Tajawal.css";
import LangTab from "../../components/LangTab";

export default function Login() {
  const [data, setData] = useState({ username: "", password: "" });
  const navigage = useNavigate();
  const { error, login } = useLogin();
  const { isClient, setIsClient, activeLang, setActiveLang } = useAppContext();

  useEffect(() => {
    if (activeLang == undefined || activeLang == null) {
      setActiveLang(LANG.ARABIC);
    }
  }, []);

  const handleGoToSignup = () => {
    // go to sign up
    navigage("/signup");
  };

  // handle login
  const handleLigin = (e) => {
    e.preventDefault();
    login(data.username, data.password, isClient, resetDoc);
  };

  const resetDoc = () => {
    data.username = "";
    data.password = "";
    setData({ ...data });
  };

  const resolveLoginKey = (loginKey) => {
    if (loginKey == Enum.CLIENT) {
      setIsClient(true);
    }
    if (loginKey == Enum.CONTRACTOR) {
      setIsClient(false);
    }
  };

  const navigate = useNavigate();

  const resolveTitleEnglish = () => {
    return `login ${isClient ? "as user" : "as contractor"}`;
  };

  const resolveTitleArabic = () => {
    return `سجل دخول ${isClient ? "كمستخدم" : "كمقاول"}`;
  };

  return (
    <div>
      <div>
        {isClient == null && (
          <div className="w-screen mt-[100px] md:w-[800px] mx-auto md:mt-[250px] text-2xl font-bold text-center">
            <div
              onClick={() => resolveLoginKey(Enum.CLIENT)}
              className="cursor-pointer mb-2 tajawal-black"
            >
              {activeLang == LANG.ARABIC
                ? "سجل دخول كمستخدم"
                : "Signin as a user"}
            </div>
            <div
              onClick={() => resolveLoginKey(Enum.CONTRACTOR)}
              className="cursor-pointer tajawal-black"
            >
              {activeLang == LANG.ARABIC
                ? "سجل دخول كمقاول"
                : "signin as contractor"}
            </div>
            <div
              onClick={() => navigate("/signup")}
              className="mt-8 text-gray-400 cursor-pointer tajawal-light"
            >
              <div>
                {activeLang == LANG.ARABIC
                  ? "ليس لي حساب على المنصه"
                  : "I do not have an account"}
              </div>
              <div>
                {activeLang == LANG.ARABIC
                  ? "احتاج انشاء حساب"
                  : "I need to register"}
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <div className="flex absolute w-[800px]" style={{ bottom: 40 }}>
            <div className="mx-auto">
              <LangTab />
            </div>
          </div>
        </div>
        {isClient != null && (
          <div className="w-[340px] mx-auto mt-[100px]">
            <div
              onClick={() => setIsClient(null)}
              className={`tajawal-block ${
                LANG.ARABIC ? "text-right" : ""
              } text-4xl font-bold cursor-default`}
            >
              {activeLang == LANG.ARABIC
                ? resolveTitleArabic()
                : resolveTitleEnglish()}
            </div>
            <div className="bg-gray-400 rounded-xl p-4 mx-auto mt-4 ">
              <div className="w-[300px] mt-[10px]">
                <div className="">
                  <TextField
                    label="username"
                    isActive={true}
                    id="username"
                    valKey="username"
                    data={data}
                    setData={setData}
                    production={true}
                    showLabel={false}
                  />
                </div>
                <div className="mt-2">
                  <TextField
                    label="password"
                    isActive={true}
                    id="password"
                    valKey="password"
                    data={data}
                    setData={setData}
                    production={true}
                    showLabel={false}
                  />
                </div>
                <div className="mt-[40px]">
                  <Button onClick={(e) => handleLigin(e)} variant="contained">
                    login
                  </Button>
                </div>
              </div>
            </div>
            <div
              onClick={handleGoToSignup}
              className={`tajawal-light ${
                activeLang == LANG.ARABIC ? "text-right" : ""
              } transition ease-in-out duration-250 cursor-pointer mt-4 text-gray-400 font-medium text-2xl hover:text-gray-700 hover:font-medium`}
            >
              <div>
                {activeLang && activeLang == LANG.ARABIC
                  ? "اذا ليس لديك حساب على المنصه"
                  : "if you don't have an account,"}
              </div>

              <div>
                {activeLang && activeLang == LANG.ARABIC
                  ? "انشئ حساب هنا "
                  : "signup here"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
