import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { db, timestamp } from "../firebase/config";
import Enum from "../abstract/Enum";

// firebase imports
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  addDoc,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useCollection } from "./useCollection";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const signup = async ({
    email,
    password,
    displayname,
    contact,
    trade,
    isClient,
  }) => {
    setError(null);
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        const user = res.user;

        const resolveRoles = () => {
          if (isClient) {
            return ["user"];
          } else {
            return ["contractor"];
          }
        };

        const addDocument = async () => {
          let d = {
            uid: user.uid,
            email: email,
            displayname: displayname,
            timestamp: timestamp.now(),
            contact: contact,
            roles: resolveRoles(),
            permissions: ["read"],
          };

          // if contractor as trade field
          if (!isClient) {
            d = { ...d, trade: trade };
          }

          console.log(d);

          const cRef = collection(
            db,
            isClient == true ? Enum.USERS : Enum.CONTRACTORS
          );
          return await addDoc(cRef, d);
        };
        if (user) {
          navigate("/");
        }
        addDocument();
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  };

  return { error, signup };
};
