import {
  collection,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  setDoc,
  getDocs,
  query,
  writeBatch,
  where,
} from "firebase/firestore";

import { db } from "../../firebase/config";

export default function Qutaibah() {
  const handleClick = async () => {
    const d = { name: "qutaibah" };
    // handle
    const cRef = collection(db, "avatars");
    await addDoc(cRef, d, { merge: true });
  };

  return (
    <div>
      <div onClick={handleClick} className="cursor-pointer">
        Hello this is qutaibah
      </div>
    </div>
  );
}
