import Button from "@mui/material/Button";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { toast } from "react-toastify";
import Enum from "../../../abstract/Enum";
import TextField from "../../../fields/TextField";
import { db } from "../../../firebase/config";
import Advert from "../models/Advert";

export default function AddAdvertForm({ advertSection, setShowAddAdvertForm }) {
  const [data, setData] = useState({ name: "" });
  const handleAddAdvert = async () => {
    if (data.name == "") {
      toast.error("invalid data");
      return;
    }
    // advert data
    const advert = new Advert();
    advert.name = data.name;
    advert.sectionId = advertSection.id;

    // section data
    advertSection.addAdvert(advert);
    const sectionData = advertSection.data();

    // save
    const docRef = doc(db, Enum.ADVERTS_SECTION, advertSection.id);
    await setDoc(docRef, sectionData, { merge: true }).then((res) => {
      setShowAddAdvertForm(false);
      toast.success("advert added");
    });
  };

  const handleCancelClick = () => {
    setShowAddAdvertForm(false);
  };

  return (
    <div>
      <div className="">
        <TextField
          label="name"
          isActive={true}
          id="name"
          valKey="name"
          data={data}
          setData={setData}
        />
      </div>
      <div className="flex mt-2">
        <Button
          onClick={handleAddAdvert}
          className="w-[250px]"
          variant="contained"
        >
          Add
        </Button>
        <div className="ml-2">
          <Button
            onClick={handleCancelClick}
            className="w-[150px]"
            variant="contained"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
