import { useEffect, useState } from "react";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import Enum from "../../abstract/Enum";
import { toast } from "react-toastify";
import { db, timestamp } from "../../firebase/config";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import EditAdvertForm from "./form/EditAdvertForm";
import AdvertImage from "./AdvertImage";

export default function AdvertRow({ advert }) {
  const [inEdit, setInEdit] = useState(false);
  const [showAdvertImage, setShowAdvertImage] = useState(false);
  const { sectionWithId } = useCollectionContext();

  useEffect(() => {
    console.log(advert);
  }, []);

  const handleEdit = () => {
    setInEdit(!inEdit);
  };

  const handleDelete = async () => {
    const advertSection = sectionWithId(advert.sectionId);
    advertSection.removeAdvert(advert.id);
    const sectionData = advertSection.data();
    const docRef = doc(db, Enum.ADVERTS_SECTION, advert.sectionId);
    await setDoc(docRef, sectionData, { merge: true }).then((res) => {
      toast.success("advert deleted");
    });
  };

  const resolveDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000).toDateString();
  };

  const resolveTimestamp = (timestamp) => {
    try {
      const t = timestamp.seconds;
      console.log(t);
      const d = new Date(t);
      const dayString = d.toDateString();
      return dayString;
    } catch (err) {
      return "";
    }
  };

  return (
    <div className="bg-blue-500 mt-1 rounded overflow-hidden">
      <div className="ml-2">
        <div className="flex">
          <div className="cursor-default mt-4 font-bold">{advert.name}</div>
          <div onClick={handleEdit} className="cursor-pointer ml-auto mr-2">
            edit
          </div>
        </div>
        <div className="cursor-default mt-4">
          start date: {resolveDate(advert.startdate)}
        </div>
        <div className="cursor-default">
          end date: {resolveDate(advert.enddate)}
        </div>
        <div
          onClick={() => setShowAdvertImage(!showAdvertImage)}
          className="cursor-pointer mt-4"
        >
          show advert images
        </div>
        {showAdvertImage && (
          <div className="py-4">
            <AdvertImage advert={advert} />
          </div>
        )}

        {inEdit && (
          <div>
            <div>
              <EditAdvertForm advert={advert} setInEdit={setInEdit} />
            </div>
            <div onClick={handleDelete} className="cursor-pointer">
              [delete]
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
