import { useEffect, useState } from "react";
import TextField from "../../../fields/TextField";
import Button from "@mui/material/Button";
import { db } from "../../../firebase/config";
import Enum from "../../../abstract/Enum";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import SelectField from "../../../fields/SelectField";
import SwitchField from "../../../fields/SwitchField";
import { Divider } from "@mui/material";

export default function ContractorEditForm({ contractor, setShowEditForm }) {
  const [data, setData] = useState(null);

  // once wehn mounting
  useEffect(() => {
    setData(contractor);
    setIsVarifed(contractor.isVarified);
    setTradeOption(contractor.trade);
    setPinnedOption(contractor.pinned);
  }, []);

  const handleUpdate = async () => {
    // handle update

    const docRef = doc(db, Enum.CONTRACTORS, contractor.id);
    await setDoc(docRef, data, { merge: true }).then(() => {
      toast.success("contractor updated");
      setShowEditForm(false);
    });
  };

  const [tradeOptions, setTradeOptions] = useState(Enum.CONT_TRADE);
  const [tradeOption, setTradeOption] = useState(null);
  const resolveTradeOption = (option) => {
    setTradeOption(option);
    setData({ ...data, trade: option });
  };

  const [isVarified, setIsVarifed] = useState(false);
  const resolveIsVarified = (val) => {
    setData({ ...data, isVarified: val });
    setIsVarifed(val);
  };

  // options = { pinnedOptions };
  // data = { pinnedOption };
  // setData = { resolvePinnedOption };

  const pinnedOptions = [
    { value: "0", label: "Unpinned" },
    { value: "1", label: "bottom" },
    { value: "2", label: "middle" },
    { value: "3", label: "top" },
  ];
  const [pinnedOption, setPinnedOption] = useState(null);
  const resolvePinnedOption = (option) => {
    setData({ ...data, pinned: option });
    setPinnedOption(option);
  };

  return (
    <div className="bg-orange-500 rounded my-1 p-4">
      {data && (
        <div>
          <div>
            <div className="">
              <div className="text-2xl font-bold mt-2 opacity-75">
                Contractor info
              </div>
              <TextField
                label="name"
                isActive={true}
                id="displayname"
                valKey="displayname"
                data={data}
                setData={setData}
              />
            </div>
            <div className="mt-1">
              <TextField
                label="contact"
                isActive={true}
                id="contact"
                valKey="contact"
                data={data}
                setData={setData}
              />
            </div>
            <div className="mt-1">
              <SelectField
                label="Trade"
                id="trade"
                valKey="trade"
                isActive={true}
                options={tradeOptions}
                data={tradeOption}
                setData={resolveTradeOption}
              />
            </div>
            <div className="mt-1">
              <SwitchField
                label="is varied"
                isActive={true}
                id="isvarified"
                valKey="isvarified"
                data={isVarified}
                setData={resolveIsVarified}
              />
            </div>
          </div>
          <div className="text-2xl font-bold mt-4 opacity-75">Advertising</div>
          <div className="mt-1">
            <SelectField
              label="pinned"
              id="pinned"
              valKey="pinned"
              isActive={true}
              options={pinnedOptions}
              data={pinnedOption}
              setData={resolvePinnedOption}
            />
          </div>
          {/* <div className="mt-1">
            <TextField
              label="pinned"
              isActive={true}
              id="pinned"
              valKey="pinned"
              data={data}
              setData={setData}
            />
          </div> */}
          <div className="flex mt-6">
            <div>
              <Button
                className="w-[200px]"
                variant="contained"
                onClick={handleUpdate}
              >
                update
              </Button>
            </div>
            <div className="ml-2">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setShowEditForm(false)}
              >
                cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
