import { Divider } from "@mui/material";
import { deleteDoc, doc } from "firebase/firestore";
import { useState } from "react";
import { toast } from "react-toastify";
import Enum from "../../abstract/Enum";
import { db } from "../../firebase/config";
import ContractorEditForm from "./forms/ContractorEditForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { pink, blue } from "@mui/material/colors";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

export default function ContractorRow({ contractor, inEdit, setInEdit }) {
  const [showEditForm, setShowEditForm] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm("delete contractor ?")) {
      // handle delete
      const docRef = doc(db, Enum.CONTRACTORS, contractor.id);
      await deleteDoc(docRef).then(() => {
        setShowEditForm(false); // maybe to prevent flicker
        setInEdit(false);
        toast.success("contractor deleted");
      });
    }
  };
  const handleShowEditForm = (e) => {
    e.preventDefault();
    setShowEditForm(!showEditForm);
  };

  const resolveTimestamp = (timestamp) => {
    try {
      const t = timestamp.seconds;
      console.log(t);
      const d = new Date(t);
      const dayString = d.toDateString();
      return dayString;
    } catch (err) {
      return "";
    }
  };

  return (
    <div>
      <div
        className="flex p-4 min-h-[40px] cursor-pointer bg-orange-400 rounded-sm mt-1"
        onClick={(e) => handleShowEditForm(e)}
      >
        <div className="grow">
          <div className="flex">
            <div
              className={`mr-3 rounded-[50%] ${
                contractor.isVarified ? "" : "invisible"
              }`}
            >
              <CheckCircleOutlineOutlinedIcon sx={{ color: blue[500] }} />
            </div>
            <div>
              <div
                className={`table my-auto ${
                  contractor.uid == undefined ? "" : "bg-gray-600 rounded p-1"
                }`}
              >
                {contractor.displayname}
              </div>
              <div className="my-auto">{contractor.contact}</div>
              <div className="my-3">{contractor.trade.label}</div>
              <div>pinned: {contractor.pinned.label}</div>
              {contractor.uid != undefined && (
                <div>
                  <div className="my-4">
                    <Divider color="black" />
                  </div>
                  <div className="my-auto">roles: {contractor.roles}</div>
                  <div className="my-auto">permi: {contractor.permissions}</div>
                </div>
              )}
              <div className="mt-2 opacity-50 text-sm">
                a member since: {resolveTimestamp(contractor.timestamp)}
              </div>
            </div>
          </div>
        </div>
        {inEdit && contractor.uid == undefined && (
          <div
            onClick={(e) => handleDelete(e)}
            className="ml-auto my-auto mr-2"
          >
            delete
          </div>
        )}
      </div>
      {showEditForm && (
        <div>
          <ContractorEditForm
            contractor={contractor}
            setShowEditForm={setShowEditForm}
          />
        </div>
      )}
    </div>
  );
}
