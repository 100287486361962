import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  redirect,
  useNavigate,
} from "react-router-dom";
import Mismaar from "./pages/landing/Mismaar";
import "react-toastify/dist/ReactToastify.css";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Navbar from "./components/Navbar";
import AddContractorForm from "./pages/contractors/forms/ContractorAddForm";

// pages
import Qutaibah from "./pages/admin/Qutaibah";
import Home from "./pages/home/Home";
import Signup from "./pages/signup/Signup";
import Profile from "./pages/profile/Profile";

import Login from "./pages/login/Login";
import { getAuth } from "firebase/auth";
import { useAuthContext } from "./hooks/useAuthContext";
import { usePermify } from "@permify/react-role";
import { useEffect } from "react";
import ContractorDetail from "./pages/contractors/ContractorDetail";
import Contractors from "./pages/contractors/Contractors";
import Advertising from "./pages/advertising/Advertising";
import SlideComp from "./pages/advertising/SlideComp";

function App() {
  const { user, authIsReady } = useAuthContext();
  const { isAuthorized } = usePermify();

  // useEffect(() => {
  //   const checkPerm = async () => {
  //     if (await isAuthorized(["admin"])) {
  //       toast.success("user authorized");
  //     } else {
  //       toast.error("user unauthorized");
  //     }
  //   };
  //   checkPerm();
  // }, [isAuthorized]);

  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={user ? <Navigate to="/" /> : <Login />}
            />

            <Route path="/slides" element={<SlideComp />} />

            <Route
              path="/contractorDetail/:id"
              element={<ContractorDetail />}
            />

            <Route
              path="/advertising"
              element={!user ? <Navigate to="/login" /> : <Advertising />}
            />
            <Route
              path="/profile"
              element={!user ? <Navigate to="/login" /> : <Profile />}
            />
            <Route
              path="/contractors"
              element={!user ? <Navigate to="/login" /> : <Contractors />}
            />

            <Route path="/signup" element={<Signup />} />
            <Route path="/qutaibah" element={<Qutaibah />} />
            <Route
              path="/addContractor"
              element={!user ? <Navigate to="/login" /> : <AddContractorForm />}
            />
          </Routes>
          {/* <div className="flex">
        <Mismaar />
      </div> */}
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Bounce}
          />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
