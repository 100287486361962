import { createContext, useContext, useEffect, useState } from "react";
import Enum from "../abstract/Enum";
import LANG from "../abstract/LANG";
import { useCollection } from "../hooks/useCollection";

// create context
export const AppContext = createContext();

// context provider

export const AppContextProvider = ({ children }) => {
  // data
  const [activeTrade, setActiveTrade] = useState(null);
  const [isClient, setIsClient] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [activeLang, setActiveLang] = useState(LANG.ARABIC);

  // load
  useEffect(() => {
    const n = window.sessionStorage.getItem("activeUser");
    const a = window.sessionStorage.getItem("activeTrade");
    const l = window.sessionStorage.getItem("activeLang");
    const c = window.sessionStorage.getItem("isClient");

    const on = JSON.parse(n);
    const oa = JSON.parse(a);
    const ol = JSON.parse(l);
    const oc = JSON.parse(c);

    setActiveUser(on);
    setActiveTrade(oa);
    setActiveLang(ol);
    setIsClient(oc);
  }, []);

  // save
  useEffect(() => {
    window.sessionStorage.setItem("activeUser", JSON.stringify(activeUser));
    window.sessionStorage.setItem("activeTrade", JSON.stringify(activeTrade));
    window.sessionStorage.setItem("activeLang", JSON.stringify(activeLang));
    window.sessionStorage.setItem("isClient", JSON.stringify(isClient));
  }, [activeUser, isClient, activeTrade, activeLang]);

  return (
    <AppContext.Provider
      value={{
        activeTrade,
        setActiveTrade,
        isClient,
        setIsClient,
        activeUser,
        setActiveUser,
        activeLang,
        setActiveLang,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
