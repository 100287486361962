import { useEffect, useState } from "react";
import TextField from "../../../fields/TextField";
import Button from "@mui/material/Button";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import Enum from "../../../abstract/Enum";
import { toast } from "react-toastify";
import SwitchField from "../../../fields/SwitchField";
import DateField from "../../../fields/DateField";

import { timestamp } from "../../../firebase/config";

export default function EditAdvertForm({ advert, setInEdit }) {
  const { sectionWithId } = useCollectionContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    let startdateObject = advert.resolveStartDateObject();
    let enddateObject = advert.resolveEndDateObject();

    startdateObject = new Date(advert.startdate.seconds * 1000);
    enddateObject = new Date(advert.enddate.seconds * 1000);

    setData({
      ...advert.data(),
      startdate: startdateObject,
      enddate: enddateObject,
    });

    // update interface
    setIsActive(advert.isActive);
  }, []);

  const handleUpdate = async () => {
    // I can not use the advert prop
    // I must use the object taht comes from the advertSection object
    const advertSection = sectionWithId(advert.sectionId);
    const a = advertSection.advertWithId(advert.id);
    a.name = data.name;
    a.isActive = data.isActive;
    a.startdate = timestamp.fromDate(data.startdate);
    a.enddate = timestamp.fromDate(data.enddate);

    console.log(a);

    const sectionData = advertSection.data();
    const docRef = doc(db, Enum.ADVERTS_SECTION, advert.sectionId);
    await setDoc(docRef, sectionData, { merge: true }).then((res) => {
      setInEdit(false);
      toast.success("advert updated");
    });
  };

  const [isActive, setIsActive] = useState(false);
  const resolveIsActive = (val) => {
    setData({ ...data, isActive: val });
    setIsActive(val);
  };

  if (data == null) {
    return (
      <div>
        <div>loading data</div>
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <TextField
          label="name"
          isActive={true}
          id="name"
          valKey="name"
          data={data}
          setData={setData}
        />
        <div className="mt-1">
          <SwitchField
            label="is active"
            isActive={true}
            id="isActive"
            valKey="isActive"
            data={isActive}
            setData={resolveIsActive}
          />
        </div>
        <div className="mt-1">
          <DateField
            label="Start Date"
            id="startdate"
            valKey="startdate"
            data={data}
            setData={setData}
            isActive={true}
          />
        </div>
        <div className="mt-1">
          <DateField
            label="End Date"
            id="enddate"
            valKey="enddate"
            data={data}
            setData={setData}
            isActive={true}
          />
        </div>
      </div>
      <div className="flex mt-2">
        <Button onClick={handleUpdate} variant="contained">
          update
        </Button>
        <div className="ml-2">
          <Button onClick={() => setInEdit(false)} variant="contained">
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
