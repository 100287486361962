import { useNavigate } from "react-router";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { pink, blue } from "@mui/material/colors";
import CallIcon from "@mui/icons-material/Call";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import "../../Tajawal.css";

export default function ContRowArabic({
  contractor,
  tileColor = "bg-blue-400",
}) {
  const [rating, setRating] = useState(4);
  const { user } = useAuthContext();

  const navigage = useNavigate();
  const handleTileClick = () => {
    // handle
    navigage(`contractorDetail/${contractor.id}`);
  };

  const resolveTimestamp = (timestamp) => {
    try {
      const t = timestamp.seconds;
      console.log(t);
      const d = new Date(t);
      const dayString = d.toDateString();
      return dayString;
    } catch (err) {
      return "";
    }
  };

  const handleCall = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (user) {
      window.open("tel:66203360", "_top");
    } else {
      if (window.confirm("you need to be logged in to make a call")) {
        navigage("/login");
      }
    }
  };

  return (
    <div
      onClick={handleTileClick}
      className={`mx-2 md:mx-auto md:w-[800px] mt-1 rounded-md ${tileColor} cursor-pointer`}
    >
      <div className="flex p-2 my-auto text-lg tajawal-black">
        <div onClick={(e) => handleCall(e)} className="ml-4 mr-2">
          اتصال
        </div>
        <div className="ml-auto mr-6">
          <div className="flex">
            <div className="ml-auto text-right">{contractor.displayname}</div>
          </div>
          <div className="text-right">{contractor.trade.aLabel}</div>
          <div className="mt-2 opacity-50 text-sm text-right">
            {resolveTimestamp(contractor.timestamp)} عضو في المنصه منذ
          </div>
        </div>

        <div
          className={`${
            contractor.isVarified ? "" : "invisible"
          } mr-1 text-left`}
        >
          <CheckCircleOutlineOutlinedIcon />
        </div>
      </div>
    </div>
  );
}
