import { useState } from "react";
import Enum from "../../abstract/Enum";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { toast } from "react-toastify";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import AdvertsSection from "./models/AdvertsSection";
import AddAdvertForm from "./form/AddAdvertForm";
import Advert from "./models/Advert";
import AdvertRow from "./AdvertRow";
import { useDocument } from "../../hooks/useDocument";

export default function Advertising() {
  const { contractors, adverts_section: adSections } = useCollectionContext();
  const { document: advertSettings } = useDocument(Enum.SETTINGs, "adverts");

  const add_adverts_section = async () => {
    const advertsSection = new AdvertsSection(Enum.LANDSCAPE_TRADE);
    const data = advertsSection.data();
    const docRef = collection(db, Enum.ADVERTS_SECTION);
    await addDoc(docRef, data).then((docRef) => {
      toast.success("section added");
    });
  };
  return (
    <div className="w-[800px] mx-auto">
      <div className="flex mt-2">
        {advertSettings && (
          <div
            className={`flex justify-center ${
              advertSettings.isActive ? "bg-green-400" : "bg-red-400"
            } p-1 min-w-[100px] rounded-full cursor-pointer select-none`}
          >
            active
          </div>
        )}
      </div>
      <div className="flex">
        <div className="cursor-pointer">advertising</div>
        <div onClick={add_adverts_section} className="ml-2 cursor-pointer">
          <AddCircleOutlineIcon />
        </div>
      </div>
      <div>
        {adSections &&
          adSections
            .map((sectionData) => AdvertsSection.fromData(sectionData))
            .map((advertSection) => {
              return (
                <div key={advertSection.id}>
                  <TradeAdvertSection
                    advertSection={advertSection}
                    trade={advertSection.trade}
                  />
                </div>
              );
            })}
      </div>
      <div></div>
    </div>
  );
}

export function TradeAdvertSection({ advertSection, trade }) {
  const [showAddAdvertForm, setShowAddAdvertForm] = useState(false);
  const [showAdverts, setShowAdverts] = useState(false);
  const handleShowAddAvertForm = () => {
    setShowAddAdvertForm(!showAddAdvertForm);
  };

  return (
    <div>
      <div
        onClick={() => setShowAdverts(!showAdverts)}
        className="flex bg-gray-400 mt-1 py-1 cursor-pointer"
      >
        <div className="text-3xl font-bold cursor-default ml-2">
          {trade && trade.label}
        </div>
        <div className="ml-4 my-auto cursor-pointer">
          <AddCircleOutlineIcon onClick={handleShowAddAvertForm} />
        </div>
      </div>
      <div>
        {showAdverts &&
          advertSection.adverts.map((advert) => (
            <div key={advert.id}>
              <AdvertRow advert={advert} />
            </div>
          ))}
      </div>
      {showAddAdvertForm && (
        <div>
          <AddAdvertForm
            advertSection={advertSection}
            setShowAddAdvertForm={setShowAddAdvertForm}
          />
        </div>
      )}
    </div>
  );
}
