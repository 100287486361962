import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import * as React from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useState } from "react";
import { useSignout } from "../../hooks/useSignout";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { collection, doc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useNavigate } from "react-router";
import { useAppContext } from "../../hooks/useAppContext";
import { useLogin } from "../../hooks/useLogin";
import { HasAccess } from "@permify/react-role";

import LANG from "../../abstract/LANG";

export default function DropMenu() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { signout } = useSignout();
  const { isClient, activeUser, activeLang, setActiveLang } = useAppContext();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, menuItemKey) => {
    setAnchorEl(null);
    setTimeout(() => {
      doSwitch();
    }, 250);

    const doSwitch = () => {
      switch (menuItemKey) {
        case "profile":
          navigate("/profile");
          return;
        case "logout":
          if (window.confirm("logout?")) {
            signout();
          }
          return;
        case "advertise":
          navigate("/advertising");
          return;
        case "contractors":
          navigate("/contractors");
          return;
        default:
          return;
      }
    };
  };

  const { users, contractors } = useCollectionContext();
  const { docForUid } = useLogin();
  const resolveUserInitial = () => {
    // if user is null
    const user = auth.currentUser;
    if (!user) return "";

    // if user loged in ... continue
    const currentUid = user.uid;
    let i = "x";
    if (users) {
      const usr = docForUid(currentUid);
      // let usr;
      // if (isClient) {
      //   usr = users.filter((u) => u.uid == currentUid)[0];
      // } else {
      //   usr = contractors.filter((c) => c.uid == currentUid)[0];
      // }
      try {
        i = usr.email.charAt(0);
      } catch (err) {
        i = "/";
      }
    }
    return i;
  };

  const resolveAccountType = () => {
    const r = activeUser.roles;
    if (r.includes("admin")) {
      return "admin";
    }
    if (r.includes("advertiser")) {
      return "advertiser";
    }
    if (r.includes("user")) {
      return "user";
    }
    if (r.includes("contractor")) {
      return "contractor";
    }
  };

  const resolveName = () => {
    return activeUser.displayname != ""
      ? activeUser.displayname
      : activeUser.email;
  };

  const [lang, setLang] = React.useState(LANG.ARABIC);
  const handleLanguage = (event, newLanguage) => {
    setActiveLang(newLanguage);
    setLang(newLanguage);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography className="bg-red-400 min-w-[100px]">Contact</Typography>
        <Typography className="bg-blue-400 min-w-[100px]">Profile</Typography> */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {resolveUserInitial()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {activeUser && (
          <div>
            <div className="px-4 cursor-default">
              <div>welcome,</div>
              <div className="">{resolveName()}</div>
              <div className="my-4">
                <Divider />
              </div>
              <div>account:</div>
              <div className="">{resolveAccountType()}</div>
              <div className="my-4">
                <Divider />
              </div>
            </div>
          </div>
        )}

        <MenuItem onClick={(e) => handleClose(e, "profile")}>
          <Avatar /> Profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider /> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        {/* <MenuItem onClick={(e) => handleClose(e, "contractor")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Contractor
        </MenuItem> */}
        <MenuItem>
          <div>langauge</div>
          <ToggleButtonGroup
            value={lang}
            exclusive
            onChange={handleLanguage}
            aria-label="text alignment"
          >
            <ToggleButton value={LANG.ARABIC} aria-label="left aligned">
              Ar
            </ToggleButton>
            <ToggleButton value={LANG.ENGLISH} aria-label="centered">
              En
            </ToggleButton>
          </ToggleButtonGroup>
        </MenuItem>
        <HasAccess roles={["admin"]}>
          <MenuItem onClick={(e) => handleClose(e, "contractors")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Contractors
          </MenuItem>
          <MenuItem onClick={(e) => handleClose(e, "advertise")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Advertise
          </MenuItem>
        </HasAccess>

        <MenuItem onClick={(e) => handleClose(e, "logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
