import { createContext, useContext, useEffect, useState } from "react";
import Enum from "../abstract/Enum";
import { useCollection } from "../hooks/useCollection";
import AdvertsSection from "../pages/advertising/models/AdvertsSection";

// create context
export const CollectionContext = createContext();

// context provider
export const CollectionContextProvider = ({ children }) => {
  // data
  const { documents: contractors } = useCollection(Enum.CONTRACTORS);
  const { documents: users } = useCollection(Enum.USERS);
  const { documents: adverts_section } = useCollection(Enum.ADVERTS_SECTION);
  const { documents: settings } = useCollection(Enum.SETTINGs);

  const sectionWithId = (id) => {
    const sectionData = adverts_section.filter(
      (section) => section.id == id
    )[0];
    if (sectionData) {
      return AdvertsSection.fromData(sectionData);
    }
    return null;
  };

  const sectionForTrade = (tradeValue) => {
    return adverts_section.filter(
      (section) => section.trade.value == tradeValue
    )[0];
  };

  const pushSettings = (key) => {};

  const pullSettings = (key) => {
    const doc = settings.filter((s) => s.id === key)[0];
    if (doc) {
      return doc;
    }
    return null;
  };

  return (
    <CollectionContext.Provider
      value={{
        contractors,
        users,
        adverts_section,
        sectionWithId,
        sectionForTrade,
        pushSettings,
        pullSettings,
        settings,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
