import { useState } from "react";
// firebase imports
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { usePermify } from "@permify/react-role";
import { db } from "../firebase/config";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCollectionContext } from "./useCollectionContext";
import { useAppContext } from "./useAppContext";
import { data } from "autoprefixer";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();
  const { users, contractors } = useCollectionContext();
  const { setUser } = usePermify();

  // const { setUser } = usePermify();
  // const { setMember } = useScopeContext();

  const navigate = useNavigate();
  const { setActiveUser } = useAppContext();

  const login = (email, password, isClient, resetDoc) => {
    setError(null);

    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        const user = res.user;

        // get user document
        // set it as the current permify user
        // const userDocument = docForUid(user.uid);
        const activeCollection = isClient == true ? users : contractors;
        const userDocument = activeCollection.filter(
          (i) => i.uid == user.uid
        )[0];

        if (userDocument == null || userDocument == undefined) {
          const msg = isClient
            ? "invalid credentials for user"
            : "invalid credentials for contractor";
          toast.error(msg);
          resetDoc();
          signOut(auth);
          return;
        }

        dispatch({ type: "LOGIN", payload: user });
        toast.success("login");

        setUser({
          id: userDocument.id,
          roles: userDocument.roles,
          permissions: userDocument.permissions,
        });

        // save on appContext
        setActiveUser(userDocument);

        navigate("/");
      })
      .catch((err) => {
        toast.error("error");
        setError(err.message);
        console.log(err.message);
      });
  };

  const docForUid = (uid) => {
    let d;
    const user = auth.currentUser;
    d = users.filter((u) => u.uid == user.uid);
    if (d.length < 1) {
      d = contractors.filter((c) => c.uid == user.uid);
    }
    return d[0];
  };

  return { error, login, docForUid };
};
