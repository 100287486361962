import { timestamp } from "../../../firebase/config";
import Advert from "./Advert";

class AdvertsSection {
  // fields
  id = "";
  order = 0;
  trade = {};
  adverts = [];
  isActive = false;
  timestamp = timestamp.now();

  constructor(trade) {
    this.trade = trade;
  }

  static fromData = (data) => {
    const i = new AdvertsSection(data.trade);
    i.id = data.id;
    i.isActive = data.isActive;
    i.timestamp = data.timestamp;
    i.trade = data.trade;
    i.order = data.order;
    i.adverts = data.adverts.map((advert) => Advert.fromData(advert));
    return i;
  };

  data = () => {
    return {
      order: this.order,
      trade: this.trade,
      adverts: this.adverts.map((advert) => advert.data()),
      isActive: this.isActive,
      timestamp: this.timestamp,
    };
  };

  addAdvert = (advert) => {
    this.adverts.push(advert);
  };
  removeAdvert = (id) => {
    this.adverts = this.adverts.filter((ad) => ad.id != id);
  };

  advertWithId = (id) => {
    return this.adverts.filter((a) => a.id == id)[0];
  };

  fuse = (advert) => {
    this.adverts = this.adverts.map((a) => {
      if (a.id != advert.id) {
        return a;
      } else {
        return advert;
      }
    });
  };
}
export default AdvertsSection;
