import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useState } from "react";
import LANG from "../abstract/LANG";
import { useAppContext } from "../hooks/useAppContext";

export default function LangTab() {
  const { activeLang, setActiveLang } = useAppContext();

  useEffect(() => {
    if (activeLang == undefined || activeLang == null) {
      setActiveLang(LANG.ARABIC);
    }
  }, []);

  // const [lang, setLang] = useState(activeLang);
  const handleLanguage = (event, newLanguage) => {
    console.log(newLanguage);
    setActiveLang(newLanguage);
    // setLang(newLanguage);
  };

  return (
    <div>
      <ToggleButtonGroup
        value={activeLang}
        exclusive
        onChange={handleLanguage}
        aria-label="text alignment"
      >
        <ToggleButton value={LANG.ARABIC} aria-label="left aligned">
          Ar
        </ToggleButton>
        <ToggleButton value={LANG.ENGLISH} aria-label="centered">
          En
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
