import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8SPbYeweVFe4dCZItRfqi8x4lXXQnG_g",
  authDomain: "mismaar-f678a.firebaseapp.com",
  databaseURL:
    "https://mismaar-f678a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mismaar-f678a",
  storageBucket: "mismaar-f678a.appspot.com",
  messagingSenderId: "518981662919",
  appId: "1:518981662919:web:38b68525a1817db991a6e2",
  measurementId: "G-Q00VNQR391",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// init firestore
const db = getFirestore();

// init authentication
const auth = getAuth();

// time stamp
const timestamp = Timestamp;

export { db, auth, timestamp, analytics };
