import { useNavigate } from "react-router";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { pink, blue } from "@mui/material/colors";
import CallIcon from "@mui/icons-material/Call";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";

export default function ContRow({ contractor, tileColor = "bg-blue-400" }) {
  const [rating, setRating] = useState(4);
  const { user } = useAuthContext();

  const navigage = useNavigate();
  const handleTileClick = () => {
    // handle
    navigage(`contractorDetail/${contractor.id}`);
  };

  const resolveTimestamp = (timestamp) => {
    try {
      const t = timestamp.seconds;
      console.log(t);
      const d = new Date(t);
      const dayString = d.toDateString();
      return dayString;
    } catch (err) {
      return "";
    }
  };

  const handleCall = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (user) {
      window.open("tel:66203360", "_top");
    } else {
      if (window.confirm("you need to be logged in to make a call")) {
        navigage("/login");
      }
    }
  };

  return (
    <div
      onClick={handleTileClick}
      className={`mx-2 md:mx-auto md:w-[800px] mt-1 rounded-md ${tileColor} cursor-pointer`}
    >
      <div className="flex p-2 my-auto text-lg">
        <div
          className={`${
            contractor.isVarified ? "" : "invisible"
          } mr-1 text-left`}
        >
          <CheckCircleOutlineOutlinedIcon />
        </div>
        <div>
          <div className="flex">
            <div className="text-left">{contractor.displayname}</div>
            {/* <div className="h-[30px] p-[2px] ml-4 bg-gray-600 rounded-md">
              <Rating size="small" name="read-only" value={rating} readOnly />
            </div> */}
          </div>
          <div className="text-left">{contractor.trade.label}</div>
          <div className="mt-2 opacity-50 text-sm text-left">
            a member since: {resolveTimestamp(contractor.timestamp)}
          </div>
        </div>
        {/* <div onClick={(e) => handleCall(e)} className="ml-auto mr-4">
          <CallIcon />
        </div> */}
        <div onClick={(e) => handleCall(e)} className="ml-auto mr-4">
          call
        </div>
        {/* <div onClick={handleCall} className="ml-auto mr-4">
          call
        </div> */}
      </div>
    </div>
  );
}
