import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useCollectionContext } from "../../hooks/useCollectionContext";

export default function ContractorDetail() {
  const { id } = useParams();
  const { contractors } = useCollectionContext();
  const [contractor, setContractor] = useState(null);

  useEffect(() => {
    if (contractors) {
      const c = contractors.filter((cont) => cont.id === id)[0];
      setContractor(c);
    }
  }, [id]);

  return (
    <div className="flex">
      <div className="w-screen mx-2 p-2 rounded md:w-[800px] md:mx-auto bg-gray-400 mt-2">
        <div className="text-2xl font-bold">contractor detail</div>
        <div>{contractor && contractor.name}</div>
        <div>{contractor && contractor.trade.label}</div>
        <div>completed jobs</div>
        <div>rating</div>
        <div>comments</div>
      </div>
    </div>
  );
}
