import Switch from "react-switch";

export default function SwitchField({
  label = "isDocument",
  id,
  data,
  setData,
  labelWidth = 300,
  isActive = false,
  valKey = "isDocument",
  required = true,
  production = false,
}) {
  return (
    <div
      className={`flex items-center h-11 ${production ? "" : "bg-yellow-400"} `}
    >
      <p className="w-[${labelWidth}px]">{label}</p>

      {isActive && (
        <Switch
          onChange={() => setData(!data)}
          checked={data}
          className="react-switch w-[300px] max-w-[300px] ml-auto"
        />
      )}
      {!isActive && (
        <p className="w-[300px] max-w-[300px] ml-auto px-2">{String(data)}</p>
      )}
    </div>
  );
}
