import { db } from "../firebase/config";
import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

// firebase imports
import { getAuth } from "firebase/auth";
import {
  collection,
  onSnapshot,
  addDoc,
  setDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export const useUploadDocument = () => {
  const auth = getAuth();

  const uploadImage = async (image, path) => {
    const url = await storeItem(image, path);
    return url;
  };

  const uploadDocument = async (file, path) => {
    const url = await storeItem(file, path);
    return url;
  };

  // -------------------------------------------------------------

  const storeItem = async (item, path, fileName = "qutaibah") => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();

      const filename = `${path}/${auth.currentUser.uid}-${item.name}-${uuid()}`;
      // const filename = `${path}/${fileName}`;
      const storageRef = ref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, item);

      console.log(filename);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log(downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  // -------------------------------------------------------------

  const uploadPdf = async (quotePdf) => {
    async function storePdf(p_quotePdf) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${quotePdf.name}-${uuid()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, p_quotePdf);

        console.log(filename);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    }

    const pdfUrl = await storePdf(quotePdf);
    return pdfUrl;
  };

  const insertDesignScopeDoc = async (scopeId, urlPdf) => {
    // const data = {
    //   urlPdf,
    //   scopeId,
    //   userRef: auth.currentUser.uid,
    // };
    // const docRef = await addDoc(collection(db, "documents"), data);

    // add the url on the designScope itself
    const docRef = doc(db, "designScopes", scopeId);
    await setDoc(docRef, { documentUrl: urlPdf }, { merge: true });
  };

  const deleteDesignScopeDoc = async (scopeId) => {
    // delete document
    // const docId = await getDocumentId(scopeId);
    // const docRef = doc(db, "documents", docId);
    // await deleteDoc(docRef);

    // delete filed
    const docRef = doc(db, "designScopes", scopeId);
    const data = { documentUrl: deleteField() };
    updateDoc(docRef, data);
  };

  const getDocumentId = async (id) => {
    const documentsRef = collection(db, "documents");
    const q = query(documentsRef, where("scopeId", "==", id));
    const querySnapshot = await getDocs(q);
    let docId = "";

    querySnapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      docId = doc.id;
      return;
    });
    return docId;
  };

  return {
    uploadDocument,
    uploadPdf,
    uploadImage,
    insertDesignScopeDoc,
    deleteDesignScopeDoc,
  };
};
