import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useAppContext } from "../../hooks/useAppContext";
import { useLogin } from "../../hooks/useLogin";
import { auth, db } from "../../firebase/config";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import TextField from "../../fields/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Enum from "../../abstract/Enum";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export default function Profile() {
  const { isClient, activeUser, setActiveUser } = useAppContext();
  const { authIsReady } = useAuthContext();
  const [data, setData] = useState({});
  const [inEditMode, setInEditMode] = useState(false);

  useEffect(() => {
    setData(activeUser);
  }, [activeUser]);

  const handleEdit = () => {
    setInEditMode(true);
  };

  const handleExitEidt = () => {
    setInEditMode(false);
  };

  const handleUpdate = async () => {
    // handle update
    const collectionKey = activeUser.roles.includes(Enum.CONTRACTOR)
      ? Enum.CONTRACTORS
      : Enum.USERS;

    const docRef = doc(db, collectionKey, data.id);
    await setDoc(docRef, data, { merge: true }).then((res) => {
      setActiveUser(data);
      setInEditMode(false);
      toast.success("profile updated");
    });
  };

  return (
    <div>
      {authIsReady && (
        <div className="flex w-[800px] bg-red-300 mx-auto">
          <div className="mt-4">
            <div className="flex">
              <div className="text-3xl font-bold">Profile</div>
              {!inEditMode && (
                <div
                  onClick={handleEdit}
                  className="my-auto ml-4 cursor-pointer"
                >
                  <EditIcon />
                </div>
              )}
              {inEditMode && (
                <div
                  className="cursor-pointer ml-4 my-auto"
                  onClick={handleExitEidt}
                >
                  done
                </div>
              )}
            </div>
            {/* <div>{isClient == null ? "" : isClient.toString()}</div> */}

            <div className="w-[600px]">
              <div className="">
                <TextField
                  label="display name"
                  isActive={inEditMode}
                  id="displayname"
                  valKey="displayname"
                  data={data}
                  setData={setData}
                />
              </div>
              <div className="mt-1">
                <TextField
                  label="contact"
                  isActive={inEditMode}
                  id="contact"
                  valKey="contact"
                  data={data}
                  setData={setData}
                />
              </div>

              <div>{activeUser.roles}</div>
              <div>{activeUser.permissions}</div>

              {inEditMode && (
                <div className="flex">
                  <div>
                    <Button
                      className="w-[150px]"
                      onClick={handleUpdate}
                      color="secondary"
                      variant="contained"
                    >
                      update
                    </Button>
                  </div>
                  <div className="ml-2">
                    <Button
                      onClick={() => setInEditMode(false)}
                      color="error"
                      variant="contained"
                    >
                      cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>

            {/* <div>to do</div>
            <div>profile page CRUD</div>
            <div>admin users profile dash CRUD</div>
            <div>admin contractor profiles dash CRUD</div>
            <div>manipulating roles and permissions</div>
            <div>delete account by user or admin</div> */}
          </div>
        </div>
      )}
    </div>
  );
}
