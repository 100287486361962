class Enum {
  static LARGE_PORT = "largePort";
  static MEDIUM_PORT = "mediumPort";
  static SMALL_PORT = "smallPort";

  // collections
  static CONTRACTORS = "contractors";
  static USERS = "users";
  static ADVERTS_SECTION = "adverts_section";
  static SETTINGs = "settings";

  static CARPENTER_TRADE = {
    value: "Carpenter",
    label: "Carpenter",
    aLabel: "نجار",
  };
  static METALWORK_TRADE = {
    value: "metal",
    label: "Metalwork",
    aLabel: "حداد",
  };
  static PAINTER_TRADE = { value: "painter", label: "Painter", aLabel: "صباغ" };
  static PLUMBING_TRADE = {
    value: "plumbing",
    label: "Plumbing",
    aLabel: "صحي",
  };
  static ELECTRICAL_TRADE = {
    value: "electrical",
    label: "Electrical",
    aLabel: "كهربائي",
  };
  static LANDSCAPE_TRADE = {
    value: "landscape",
    label: "Landscape",
    aLabel: "زراع",
  };

  // TRADES
  static CONT_TRADE = [
    Enum.CARPENTER_TRADE,
    Enum.METALWORK_TRADE,
    Enum.PAINTER_TRADE,
    Enum.PLUMBING_TRADE,
    Enum.ELECTRICAL_TRADE,
    Enum.LANDSCAPE_TRADE,
  ];

  // account types
  static ADMIN = "admin";
  static CONTRACTOR = "contractor";
  static CLIENT = "client";
  static ADVERTISER = "advertiser";
}
export default Enum;
