import { useNavigate } from "react-router";
import { useSignout } from "../hooks/useSignout";
import DropMenu from "../pages/dropMenu/DropMenu";
import TradeSelect from "../pages/dropMenu/TradeSelect";
import { useAppContext } from "../hooks/useAppContext";
import { getAuth } from "firebase/auth";

export default function Navbar() {
  const { signout } = useSignout();
  const { activeTrade, setActiveTrade, setIsClient } = useAppContext();

  const navigate = useNavigate();
  const handleClick = () => {
    // handle clieck
    navigate("/addContractor");
  };
  const handleSignout = () => {
    // sign out
    signout();
  };

  const handleClickOnHome = () => {
    navigate("/");
  };

  const handleClickToSlides = () => {
    navigate("/slides");
  };

  const user = getAuth().currentUser;

  const handleLogin = () => {
    setIsClient(null);
    navigate("/login");
  };

  return (
    <div className="sticky top-0 z-20">
      <div className="flex bg-gray-500 min-h-[60px] w-screen">
        <div className="w-screen ml-4 mr-2 md:w-[800px] flex my-auto md:mx-auto text-white cursor-pointer">
          <div className="text-4xl font-bold">
            <div className="flex">
              <div onClick={handleClickOnHome}>mismaar</div>
            </div>
            {/* <div className="flex sm:hidden mb-2">
              <TradeSelect width={"w-[146px]"} />
            </div> */}
          </div>
          {!user && (
            <div className="flex my-auto ml-auto">
              <div onClick={handleLogin} className="">
                login
              </div>
            </div>
          )}
          {user && (
            <div className="flex ml-auto">
              {/* <div className="hidden sm:flex">
                <TradeSelect />
              </div> */}
              <div className="">
                <DropMenu />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
