import { useEffect, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { toast } from "react-toastify";
import { useAppContext } from "../../hooks/useAppContext";
import { useCollectionContext } from "../../hooks/useCollectionContext";

export default function SlideComp() {
  const { activeTrade } = useAppContext();
  const { adverts_section: sections } = useCollectionContext();

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    buildUrls();
  }, []);

  useEffect(() => {
    // toast.success("hello");
    if (sections) {
      buildUrls();
    }
  }, [activeTrade, sections]);

  const buildUrls = () => {
    if (sections) {
      const section = sections.filter(
        (s) => s.trade.value === activeTrade.value
      )[0];
      const adverts = section.adverts;
      const results = [];
      adverts.forEach((a) => {
        results.push({ url: a.largeImg });
      });
      setSlides(results);
      console.log(results);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const MINUTE_MS = 3000;
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, [currentIndex]);

  if (slides.length < 1) {
    return (
      <div>
        <div>loading</div>
        <div>{slides.length}</div>
      </div>
    );
  }

  return (
    <div>
      {/* <div className="max-w-[800px] px-4 w-full m-auto text-3xl relative font-bold">
        {activeTrade.label}
      </div> */}
      <div className="max-w-[800px] h-[200px] w-full m-auto relative group">
        {slides.length > 0 && (
          <div
            className="w-full h-full rounded-xl  bg-center bg-cover duration-250 "
            style={{
              backgroundImage: `url(${slides[currentIndex].url} )`,
            }}
          ></div>
        )}

        {/* <div
          onClick={prevSlide}
          className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <BsChevronCompactLeft size={30} />
        </div>
        <div
          onClick={nextSlide}
          className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <BsChevronCompactRight size={30} />
        </div> */}
      </div>
    </div>
  );
}

// {
//   url: "https://images.unsplash.com/photo-1682687982093-4773cb0dbc2e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8",
// },
// {
//   url: "https://images.unsplash.com/photo-1708356474455-94f5ed413baf?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHx8",
// },
// {
//   url: "https://images.unsplash.com/photo-1708614896043-9ff9d03d641d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHx8",
// },
