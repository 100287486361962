import { HasAccess } from "@permify/react-role";
import { useNavigate } from "react-router";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState, useEffect } from "react";
import TradeSelect from "../dropMenu/TradeSelect";
import AdvertConsumer from "../advertising/AdvertConsumer";
import { useDocument } from "../../hooks/useDocument";

import "../../Tajawal.css";

import * as React from "react";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

// images
import carpenter from "../../assets/carpenter.png";
import metalwork from "../../assets/metalwork.png";
import painting from "../../assets/pn.png";
import plumbing from "../../assets/plumbing.png";
import electrical from "../../assets/electrical.png";
import Enum from "../../abstract/Enum";
import ContRow from "./ContRow";

import DropMenu from "../dropMenu/DropMenu";
import { useAppContext } from "../../hooks/useAppContext";

// mui
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { duration } from "@mui/material";
import LANG from "../../abstract/LANG";
import ContRowArabic from "./ContRowArabic";
import SlideComp from "../advertising/SlideComp";

const trades = [
  { ...Enum.CARPENTER_TRADE, img: carpenter },
  { ...Enum.METALWORK_TRADE, img: metalwork },
  { ...Enum.PAINTER_TRADE, img: painting },
  { ...Enum.PLUMBING_TRADE, img: plumbing },
  { ...Enum.ELECTRICAL_TRADE, img: electrical },
];

export default function Home({}) {
  const { user, authIsReady } = useAuthContext();
  const { document: adSettings } = useDocument(Enum.SETTINGs, "adverts");
  const { contractors } = useCollectionContext();
  const { activeTrade, setActiveTrade, activeLang, setActiveLang } =
    useAppContext();
  // const [activeTrade, setActiveTrade] = useState(null);

  const filteredList = () => {
    if (activeTrade == null) return contractors;
    const results = contractors.filter(
      (c) => c.trade.value == activeTrade.value
    );

    // resolve pinned
    const unpinned = results.filter((c) => +c.pinned.value == 0);
    const pinned = results.filter((c) => +c.pinned.value > 0);
    return unpinned;
  };

  const pinnedList = () => {
    if (activeTrade == null) return contractors;
    const results = contractors.filter(
      (c) => c.trade.value == activeTrade.value
    );

    const pinned = results
      .filter((c) => +c.pinned.value > 0)
      .sort((a, b) => (+a.pinned.value < +b.pinned.value ? 1 : -1));

    return pinned;
  };

  const resolvePinnedColor = (contractor) => {
    switch (contractor.pinned.value) {
      case "1":
        return "bg-orange-300";
      case "2":
        return "bg-orange-400";
      case "3":
        return "bg-orange-500";
      default:
        return "bg-yellow-300";
    }
  };

  const [open, setOpen] = useState(false);
  const resolveLeft = () => {
    return open ? "0px" : "-100vw";
  };

  const handleCloseMenu = (e, trade) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveTrade(trade);
    setOpen(false);
  };

  useEffect(() => {
    if (activeLang == undefined || activeTrade == null) {
      setActiveLang(LANG.ARABIC);
    }
    if (activeTrade == undefined || activeTrade == null) {
      setActiveTrade(Enum.CARPENTER_TRADE);
    }
  }, []);

  const menuTitle = "... انا ابحث عن";

  const resolveTitle = () => {
    return activeLang == LANG.ARABIC ? activeTrade.aLabel : activeTrade.label;
  };

  return (
    <div className="">
      {authIsReady && (
        <div className="flex ">
          <div className="font-bold mx-auto mt-1 ">
            {/* <div className="text-center">mismaar</div> */}
            {/* <div className="text-center">
           <HasAccess
            roles={["admins"]}
            renderAuthFailed={<p>You are not authorized to access!</p>}
          >
            <div>I am admin</div>
          </HasAccess> 
        </div> */}
            <div className="">
              <div className="relative">
                <div className="md:w-[800px] md:mx-auto">
                  <div
                    className={`tajawal-black ${
                      activeLang == LANG.ARABIC ? "text-right" : ""
                    } text-3xl font-bold ml-4 md:ml-0 my-2`}
                  >
                    {activeTrade && resolveTitle()}
                  </div>
                </div>
                {adSettings && adSettings.isActive && (
                  <div className="w-screen sticky top-[60px] z-10">
                    <div className="flex relative bg-gray-500 mx-2 md:w-[800px] md:mx-auto min-h-[200px] rounded-lg">
                      <div className=" w-full z-10">
                        {/* <AdvertConsumer /> */}
                        <SlideComp />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex mx-auto">
                  {/* <img src="https://www.dropbox.com/scl/fi/dbm051spv7p90hi3odulg/landscape_small_3.jpg?rlkey=utcb1oq79eqav7csd7iivrsk8&raw=1" /> */}
                  {/* <img src="https://www.dropbox.com/scl/fi/sg8qhvqa8ak5o9yl25r16/landscape_small_2.jpg?rlkey=irn8h0wiiqxr38mtvguzlpmkn&raw=1" /> */}
                  {/* <img src="https://www.dropbox.com/scl/fi/l5g844y4kc90497f7m6e2/landscape_small_1.jpg?rlkey=lseeeyqvf5jh0foijtj6wvgmy&raw=1" /> */}
                </div>
                {contractors &&
                  pinnedList().map((c) => (
                    <div key={c.id} className="w-screen">
                      {activeLang == LANG.ARABIC ? (
                        <ContRowArabic
                          contractor={c}
                          tileColor={resolvePinnedColor(c)}
                        />
                      ) : (
                        <ContRow
                          contractor={c}
                          tileColor={resolvePinnedColor(c)}
                        />
                      )}
                    </div>
                  ))}
                {contractors &&
                  filteredList().map((c) => (
                    <div key={c.id} className="w-screen">
                      {activeLang == LANG.ARABIC ? (
                        <ContRowArabic
                          contractor={c}
                          tileColor="bg-yellow-300"
                        />
                      ) : (
                        <ContRow contractor={c} tileColor="bg-yellow-300" />
                      )}
                    </div>
                  ))}

                <div className="w-screen md:w-[800px] md:mx-auto">
                  <div className="flex h-[200px] mx-2 md:mx-0 mt-2 bg-gray-200">
                    <div className="mx-auto my-auto text-gray-400 text-3xl">
                      Mismaar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => setOpen(!open)}
        className={`absolute bg-yellow-400 z-40 w-[100vw] h-[100vh]`}
        style={{
          position: "fixed",
          top: 0,
          left: resolveLeft(),
          transition: "left ease 0.3s",
        }}
      >
        <div className="p-4">
          {/* <div className="text-3xl mt-4">I am looking for</div> */}
          <div className="text-3xl mt-4 ml-auto mr-2 tajawal-black text-right">
            {menuTitle}
          </div>
          <div className="font-light text-2xl">
            {Enum.CONT_TRADE.map((trade) => (
              <div
                key={trade.value}
                onClick={(e) => handleCloseMenu(e, trade)}
                className="table text-gray-600 hover:text-gray-700 mt-2 tajawal-bold ml-auto mr-2 cursor-pointer"
              >
                {trade.aLabel}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        onClick={() => setOpen(!open)}
        className="flex sticky bottom-[20px] ml-auto mr-2 bg-gray-700 w-[70px] h-[70px] rounded-[50%]"
      >
        <div className="m-auto text-yellow-400 font-bold tajawal-black text-2xl cursor-pointer">
          بحث
        </div>
      </div>
    </div>
  );
}

// style={{ bottom: 20, right: 20 }}

export function FilterTile({ trade, activeTrade, setActiveTrade }) {
  const handleClick = () => {
    // handle click
    setActiveTrade(trade);
  };
  return (
    <div onClick={handleClick} className="cursor-pointer">
      <img
        src={trade.img}
        className="w-[100px] h-[100px] bg-gray-400 mr-1 cursor-pointer text-sm"
      />
      <div
        className={`${
          activeTrade && activeTrade.value == trade.value ? "bg-gray-400" : ""
        } mt-2 rounded`}
      >
        {trade.label}
      </div>
    </div>
  );
}
