export default function TextField({
  label = "name",
  id,
  data,
  setData,
  labelWidth = 300,
  inputWidth = 300,
  fullWidth = false,
  isActive = false,
  valKey = "name",
  required = true,
  production = false,
  showLabel = true,
  suffex = "",
}) {
  return (
    <div
      className={`flex items-center h-11 ${production ? "" : "bg-yellow-400"} `}
    >
      {showLabel && <p className="w-[${labelWidth}px]">{label}</p>}

      {isActive && (
        <input
          className={`${fullWidth ? "w-screen" : `w-[${inputWidth}px]`} ${
            showLabel == false ? "" : "max-w-[300px] ml-auto"
          } h-11 px-2 border border-gray-500 rounded`}
          type="text"
          id={id}
          placeholder={label}
          onChange={(e) => {
            setData({
              ...data,
              [valKey]: e.target.value,
            });
          }}
          value={data[valKey]}
          required={required}
        />
      )}
      {!isActive && (
        <div className="w-[300px] max-w-[300px] ml-auto px-2">
          {data[valKey] ? (
            <div>
              <span>{data[valKey]}</span>
              <span className="ml-1">{suffex != "" ? suffex : ""}</span>
            </div>
          ) : (
            "undifined"
          )}
        </div>
      )}
    </div>
  );
}
