import { db } from "../firebase/config";
import { useState, useEffect } from "react";

// firebase imports
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  where,
} from "firebase/firestore";

export const useCollection = (c) => {
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    let ref = collection(db, c);

    const unsub = onSnapshot(ref, (snapshot) => {
      let results = [];

      snapshot.docs.forEach((snap) => {
        // insert the id from the snap
        results.push({ id: snap.id, ...snap.data() });
      });
      setDocuments(results);
    });

    // cleanup function
    return () => unsub;
  }, [c]);

  return { documents };
};
