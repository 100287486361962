import {
  collection,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  setDoc,
  getDocs,
  query,
  writeBatch,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { db, timestamp } from "../../../firebase/config";
import TextField from "../../../fields/TextField";
import SelectField from "../../../fields/SelectField";
import { useState } from "react";
import Button from "@mui/material/Button";
import { data } from "autoprefixer";
import Enum from "../../../abstract/Enum";
import { useCollectionContext } from "../../../hooks/useCollectionContext";
import UploadImage from "../components/UploadImage";
import { useUploadDocument } from "../../../hooks/useUploadDocument";
import SwitchField from "../../../fields/SwitchField";
// component
export default function ContractorAddForm({ setShowAddForm }) {
  const [data, setData] = useState({
    displayname: "",
    trade: null,
    isVarified: false,
    rateing: 0,
    country: "kuwait",
  });
  const { contractors } = useCollectionContext();

  const handleAddContractor = async () => {
    // validate
    if (data.displayname == "" || tradeOption == null) {
      toast.error("invalid form entry");
      return;
    }
    // contractor data
    const d = {
      ...data,
      timestamp: timestamp.now(),
      pinned: { value: "0", label: "Unpinned" },
    };

    const cRef = collection(db, Enum.CONTRACTORS);
    await addDoc(cRef, d, { merge: true }).then((ref) => {
      restoreData();
      toast.success("aded");
      setShowAddForm(false);
    });
  };

  const restoreData = () => {
    setData({ name: "", trade: null });
    setTradeOption(null);
  };

  const [tradeOptions, setTradeOptions] = useState(Enum.CONT_TRADE);
  const [tradeOption, setTradeOption] = useState(null);
  const resolveTradeOption = (option) => {
    setTradeOption(option);
    setData({ ...data, trade: option });
  };

  // ---------------------------------------------------------------

  const { uploadImage } = useUploadDocument();

  const handleUploadImage = async () => {
    // validate info
    // if (data.name == "" || typeData == null || category == null) {
    //   toast.error("please fill in all fields");
    //   return;
    // }

    // validate image
    // if (data.file == undefined || data.file == "") {
    //   toast.error("please select an image");
    //   return;
    // }

    await uploadImage(file, "avatars").then((url) => {
      console.log(url);
      // once image is updaed,
      // proceed with updates to firestore
      // const itemData = {};
      // const collectionRef = collection(db, "uitems");
      // addDoc(collectionRef, itemData).then((docRef) => {
      //   setShowAddItemForm(false);
      // });
    });
  };

  // ---------------------------------------------------------------
  const [file, setFile] = useState(null);
  const handleFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    console.log(file);
    handleUploadImage();
  };

  const handleClose = () => {
    setShowAddForm(false);
  };

  const [isVarified, setIsVarifed] = useState(false);
  const resolveIsVarified = (val) => {
    setData({ ...data, isVarified: val });
    setIsVarifed(val);
  };

  return (
    <div className="mx-auto mt-4">
      <div className="">
        <div className="mt-1">
          <div className="w-[800px] mx-auto bg-orange-400 p-4 rounded-md">
            <div className="cursor-default text-3xl mb-4 font-bold">
              Contractors
            </div>
            <div className="">
              <TextField
                label="name"
                isActive={true}
                id="displayname"
                valKey="displayname"
                data={data}
                setData={setData}
              />
              <div className="mt-1">
                <TextField
                  label="contact"
                  isActive={true}
                  id="contact"
                  valKey="contact"
                  data={data}
                  setData={setData}
                />
              </div>
              <div className="mt-1">
                <SelectField
                  label="Trade"
                  id="trade"
                  valKey="trade"
                  isActive={true}
                  options={tradeOptions}
                  data={tradeOption}
                  setData={resolveTradeOption}
                />
                <div className="mt-1">
                  <SwitchField
                    label="is varied"
                    isActive={true}
                    id="isvarified"
                    valKey="isvarified"
                    data={isVarified}
                    setData={resolveIsVarified}
                  />
                </div>
                <div className="mt-2">
                  <UploadImage callBack={handleFile} />
                </div>
              </div>
            </div>

            <div className="flex mt-6">
              <Button
                onClick={handleAddContractor}
                className="w-[200px]"
                variant="contained"
              >
                Add Contractor
              </Button>
              <div className="ml-2">
                <Button
                  onClick={handleClose}
                  className="w-[100px]"
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {/* {contractors &&
          contractors.map((cont) => (
            <div key={cont.id} className="ml-4">
              <ContRow contractor={cont} />
            </div>
          ))} */}
      </div>
    </div>
  );
}

export function ContRow({ contractor }) {
  const handleDelete = async () => {
    // delete
    const docRef = doc(db, "contractors", contractor.id);
    await deleteDoc(docRef).then((ref) => {
      toast.success("deleted");
    });
  };
  return (
    <div className="flex">
      <div className="w-[150px]">{contractor.name}</div>
      <div onClick={handleDelete} className="ml-4 cursor-pointer">
        delete
      </div>
    </div>
  );
}
