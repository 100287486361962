import { timestamp } from "../../../firebase/config";
import { v4 as uuid } from "uuid";

class Advert {
  id = uuid();
  sectionId = "";
  name = "";
  startdate = timestamp.now();
  enddate = timestamp.now();
  order = "0";
  isActive = false;
  largeImg = "";
  mediumImg = "";
  smallImg = "";

  constructor() {}

  data = () => {
    return {
      id: this.id,
      name: this.name,
      startdate: this.startdate,
      enddate: this.enddate,
      order: this.order,
      sectionId: this.sectionId,
      isActive: this.isActive,
      largeImg: this.largeImg,
      mediumImg: this.mediumImg,
      smallImg: this.smallImg,
    };
  };

  static fromData = (data) => {
    const i = new Advert();
    i.id = data.id;
    i.sectionId = data.sectionId;
    i.name = data.name;
    i.startdate = data.startdate;
    i.enddate = data.enddate;
    i.order = data.order;
    i.isActive = data.isActive;
    i.largeImg = data.largeImg;
    i.mediumImg = data.mediumImg;
    i.smallImg = data.smallImg;
    return i;
  };

  resolveStartDateObject = () => {
    return new Date(this.startdate.seconds);
  };

  resolveEndDateObject = () => {
    return new Date(this.enddate.seconds);
  };
}
export default Advert;
