import { useUploadDocument } from "../../../hooks/useUploadDocument";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { db } from "../../../firebase/config";

export default function UploadImage({ item = null, callBack = null }) {
  const { uploadImage } = useUploadDocument();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleInput = async (e) => {
    // if we need to update the image on the item
    if (item != null) {
      const file = e.target.files[0];
      await uploadImage(file, "undertone").then((url) => {
        const itemData = { images: [...item.images, url] };
        const docRef = doc(db, "uitems", item.id);
        setDoc(docRef, itemData, { merge: true }).then((docRef) => {
          toast.success("image uploaded");
        });
      });
    }
    // if we are adding a new image to an item
    if (callBack != null) {
      callBack(e);
    }
  };

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput
          onChange={handleInput}
          type="file"
          accept=".jpg,.png"
        />
      </Button>
    </div>
  );
}
