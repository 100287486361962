import { useState } from "react";
import Button from "@mui/material/Button";
import { useSignup } from "../../hooks/useSignup";
import { toast } from "react-toastify";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import { useNavigate } from "react-router";
import Enum from "../../abstract/Enum";

// data filed
import TextField from "../../fields/TextField";
import SelectField from "../../fields/SelectField";
import { useAppContext } from "../../hooks/useAppContext";
import LANG from "../../abstract/LANG";

export default function Signup() {
  const { activeLang } = useAppContext();
  const [data, setData] = useState({
    email: "",
    password: "",
    displayname: "",
    contact: "",
    isClient: false,
  });
  const { signup } = useSignup();
  const { users } = useCollectionContext();
  const navigate = useNavigate();

  const handleSignup = () => {
    // validate for users
    if (data.email == "" || data.password == "") {
      toast.error("in valid input");
      return;
    }

    // extra validation for contractors
    if (
      !isClient &&
      (data.contact == "" || data.displayname || data.trade == null)
    ) {
      toast.error("in valid input");
      return;
    }

    signup(data).then((ref) => {
      // reset form
      data.name = "";
      data.password = "";
      data.displayname = "";
      data.trade = null;
      data.contact = "";
      data.isClient = false;
      setData({ ...data });
      setTradeOption(null);
    });
  };
  const handleGoToLogin = () => {
    navigate("/login");
  };

  const resolveIsClient = (value) => {
    setIsClient(value);
    setData({ ...data, isClient: value });
  };

  const [isClient, setIsClient] = useState(null);

  const [tradeOptions, setTradeOptions] = useState(Enum.CONT_TRADE);
  const [tradeOption, setTradeOption] = useState(null);
  const resolveTradeOption = (option) => {
    setTradeOption(option);
    setData({ ...data, trade: option });
  };

  const resolveUserTitle = () => {
    return activeLang == LANG.ARABIC ? "انشئ حساب مستخدم" : "signup as a user";
  };
  const resolveContractorTitle = () => {
    return activeLang == LANG.ARABIC
      ? "انشئ حساب مقاول"
      : "signup as a contractor";
  };

  return (
    <div className="flex md:w-[600px] md:mx-auto">
      <div className="mx-auto">
        {isClient == null && (
          <div className="mt-[100px] md:mt-[250px] text-center">
            <div
              onClick={() => resolveIsClient(true)}
              className="tajawal-block text-2xl font-bold cursor-pointer"
            >
              {activeLang == LANG.ARABIC
                ? "انشئ حساب مستخدم"
                : "signup as a user"}
            </div>
            <div
              onClick={() => resolveIsClient(false)}
              className="text-2xl font-bold cursor-pointer mt-[5px]"
            >
              {activeLang == LANG.ARABIC
                ? "انشئ حساب مقاول"
                : "signup as a contractor"}
            </div>

            <div
              onClick={() => navigate("/login")}
              className="tajawal-block mt-8 text-gray-400 font-light cursor-pointer text-2xl"
            >
              <div>
                {activeLang == LANG.ARABIC
                  ? "لدي حساب على المنصه"
                  : "I need to signin"}
              </div>
              <div>
                {activeLang == LANG.ARABIC
                  ? "اريد تسجيل الدخول"
                  : "I have an account"}
              </div>
            </div>
          </div>
        )}

        {isClient != null && (
          <div>
            <div
              onClick={() => setIsClient(null)}
              className={`${
                activeLang == LANG.ARABIC ? "text-right" : ""
              } mt-4 text-3xl ml-4 w-[600px] font-bold md:mt-[150px] mb-4 cursor-default`}
            >
              {isClient ? resolveUserTitle() : resolveContractorTitle()}
            </div>
            <div className="">
              <div className="w-screen">
                <div className="mx-4 bg-orange-500 p-2 rounded-md md:w-[600px]">
                  <div className="">
                    <TextField
                      label="email"
                      isActive={true}
                      id="email"
                      valKey="email"
                      data={data}
                      setData={setData}
                      showLabel={false}
                      production={true}
                      fullWidth={true}
                    />
                  </div>
                  <div className="mt-2">
                    <TextField
                      label="password"
                      isActive={true}
                      id="password"
                      valKey="password"
                      data={data}
                      setData={setData}
                      showLabel={false}
                      production={true}
                      fullWidth={true}
                    />
                  </div>

                  {!isClient && (
                    <div>
                      <div className="mt-2">
                        <TextField
                          label="display name"
                          isActive={true}
                          id="displayname"
                          valKey="displayname"
                          data={data}
                          setData={setData}
                          showLabel={false}
                          production={true}
                          fullWidth={true}
                        />
                      </div>
                      <div className="mt-2">
                        <TextField
                          label="contact"
                          isActive={true}
                          id="contact"
                          valKey="contact"
                          data={data}
                          setData={setData}
                          showLabel={false}
                          production={true}
                          fullWidth={true}
                        />
                      </div>
                      <div className="mt-2">
                        <SelectField
                          label="Trade"
                          id="trade"
                          valKey="trade"
                          isActive={true}
                          options={tradeOptions}
                          data={tradeOption}
                          setData={resolveTradeOption}
                          showLabel={false}
                          production={true}
                          fullWidth={true}
                        />
                      </div>
                    </div>
                  )}

                  {/* <div className="mt-1">
          <SwitchField
            label="I am a contractor"
            isActive={
              userHasAccess(["admin"], ["projectEdit"]) ? isActive : false
            }
            id="haSupervision"
            valKey="haSupervision"
            data={hasSupervision}
            setData={setHasSupervision}
          />
        </div> */}

                  <div className="mt-2">
                    <Button onClick={handleSignup} variant="contained">
                      signup
                    </Button>
                  </div>
                  {/* <div className="mt-2">
                    {users &&
                      users.map((u) => (
                        <div key={u.id}>
                          <div>{u.email}</div>
                        </div>
                      ))}
                  </div> */}
                </div>

                <div
                  onClick={handleGoToLogin}
                  className={`${
                    activeLang == LANG.ARABIC ? "text-right" : ""
                  } cursor-pointer ml-4 mt-4 w-[600px] text-gray-400 font-medium text-2xl hover:text-gray-700  hover:font-medium`}
                >
                  <div>
                    {activeLang == LANG.ARABIC
                      ? "لدي حساب على المنصه"
                      : "I already have an account,"}
                  </div>
                  <div>
                    {activeLang == LANG.ARABIC
                      ? "اريد تسجيل الدخول"
                      : "login here"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
