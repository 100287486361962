import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PermifyProvider } from "@permify/react-role";

import { CollectionContextProvider } from "./context/CollectionContext";
import { AuthContextProvider } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <AppContextProvider>
      <CollectionContextProvider>
        <PermifyProvider>
          <App />
        </PermifyProvider>
      </CollectionContextProvider>
    </AppContextProvider>
  </AuthContextProvider>
  // </React.StrictMode>
);
