import { useState } from "react";
import { useCollectionContext } from "../../hooks/useCollectionContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddContractorForm from "./forms/ContractorAddForm";
import ContractorRow from "./ContractorRow";

export default function Contractors() {
  const { contractors } = useCollectionContext();
  const [inEdit, setInEdit] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const handleShowAddForm = () => {
    setShowAddForm(!showAddForm);
  };

  return (
    <div className="w-[800px] mx-auto mt-4">
      <div>
        <div className="flex">
          <div className="text-3xl font-bold cursor-default">
            {contractors.length} Contractors
          </div>
          <div className="ml-4 my-auto cursor-pointer">
            <AddCircleOutlineIcon onClick={handleShowAddForm} />
          </div>
          <div className="flex ml-auto my-auto">
            {!inEdit && (
              <div onClick={() => setInEdit(true)} className="cursor-pointer">
                edit
              </div>
            )}
            {inEdit && (
              <div
                onClick={() => setInEdit(false)}
                className="ml-auto cursor-pointer"
              >
                done
              </div>
            )}
          </div>
        </div>
        {showAddForm && (
          <div className="my-4">
            <AddContractorForm setShowAddForm={setShowAddForm} />
          </div>
        )}
        {contractors &&
          contractors.map((contractor) => (
            <div key={contractor.id}>
              <div>
                <ContractorRow
                  contractor={contractor}
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
